<script>
	import Heading from '$lib/components/Heading.svelte'
	import Pilot from '$lib/components/Pilot.svelte'
</script>

<div class="grid lg:grid-cols-2 lg:items-center gap-10">
	<p class="prose text-inherit">
		A proposed convention for website owners and digital service providers to demonstrate that their digital infrastructure runs on green electricity. This is achieved by reusing existing governance
		structures, already published data, and existing industry standards as much possible.
	</p>
	<Pilot />
</div>
